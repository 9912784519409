export const setCookieToHash = (inputs: { [key in any]: {value: any, validation: {}} }, pageName: string) => {
  let formExpired = true
  document.cookie.split(';').forEach(function(value) {
    const data = value.split('=')[0].split(`${pageName}-`)

    // @ts-ignore
    if (data.length == 2 && inputs[data[1]]) {
      // @ts-ignore
      inputs[data[1]].value = decodeURIComponent(value.split('=')[1])
      if(data[1] == "formExpired") {
        formExpired = false
      }
    }
    inputs.formExpired.value = formExpired
  })
}

export const getValuesFromCookieToInputs = (inputName: string, pageName: string) => {
  let initValue = ''
  document.cookie.split(';').forEach(function(value) {
    const data = value.split('=')

    // @ts-ignore
    if (data[0].trim() == `${pageName}-${inputName}`) {
      // @ts-ignore
      initValue = decodeURIComponent(data[1] ? data[1].replace(/<br>/g, "\n") : '')
    }
  })

  return initValue
}

export const getValueFromCookie = (cookieName: string) => {
  let returnValue = ''
  document.cookie.split(';').forEach(function(value) {
    const data = value.split('=')

    // @ts-ignore
    if (data[0].trim() == cookieName) {
      // @ts-ignore
      returnValue = decodeURIComponent(data[1] ? data[1].replace(/<br>/g, "\n") : '')
    }
  })

  return returnValue
}

export const setValueFromCookie = (value: string, inputName: string, pageName?: string) => {
  document.cookie = `${pageName ? `${pageName}-` : ''}${inputName}=${encodeURIComponent(value).replace(/\n/g, "<br>")}; max-age=172800; path=/`
}

export const deleteCookies = (pageName: string) => {
  // document.cookie = "削除対象の名前=; max-age=0";
  document.cookie.split(';').forEach(function(value) {
    const data = value.split('=')[0].split(`${pageName}-`)

    // @ts-ignore
    if (data.length == 2) {
      console.log(`${pageName}-${data[1]}`)
      if(data[1] == "formExpired") {
        document.cookie = `${pageName}-${data[1]}=true; max-age=3600; path=/;`
      } else {
        document.cookie = `${pageName}-${data[1]}=; max-age=0; path=/`
      }
    }
  })
}
